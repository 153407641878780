import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import Hero from '../components/bg-img-hero-1';

const Service = (props) => {
  const { heroImage } = props.data;
  const { img1 } = props.data;
  return (
    <Layout bodyClass="page-home">
      <SEO
      title="Artists Choice - Wisconsin Framing Company"
      description="Wisconsin Custom Framing - In Person - Or By Mail - Oversize Framing - Shadowbox Framing - And More!"
      />
      <Hero 
      background={heroImage.childImageSharp.fluid} 
      headline={"Minneapolis, MN Shadowbox Framing"}
      subhead={'Mail-To, Drop-Off, or Walk-in Shadowbox Framing'}
      />
      <div className="container pb-4  pb-md-8 border-top pt-4 ">
      <p>Test</p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: file( relativePath: { eq: "wisconsin-framing-company.jpg" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file( relativePath: { eq: "affordable-custom-framing-wisconsin.png" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Service;
